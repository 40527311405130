<!-- frontend/src/layouts/DefaultLayout.vue --><!-- DefaultLayout.vue -->
<template>
  <div class="flex flex-col h-full pt-safe-top">
    <!-- TopNavbar for Desktop -->
    <TopNavbar v-if="isDesktop" :is-mobile="isMobile" :is-tablet="isTablet" />
    <!-- Main Content Area -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <main class="flex-1 overflow-y-auto">
        <slot></slot>
      </main>
      <!-- ResponsiveAudioPlayer -->
      <ResponsiveAudioPlayer
        :has-bottom-navbar="isMobile || isTablet"
        class="flex-shrink-0"
      />
      <!-- BottomNavbar for Mobile and Tablet -->
      <BottomNavbar
        v-if="isMobile || isTablet"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
        class="flex-shrink-0"
      />
      <div
        :class="[
          'bg-gray-100',
          { 'pb-1.5': !isIOS && !isAndroid && (isMobile || isTablet) },
          { 'pb-safe-bottom': isIOS },
          { 'pb-1.5': isAndroid },
        ]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useResponsive } from '@/composables/useResponsive'
import ResponsiveAudioPlayer from '@/components/ResponsiveAudioPlayer.vue'
// import { notify } from '@kyvg/vue3-notification'
import { Capacitor } from '@capacitor/core'

// const OnboardingModal = defineAsyncComponent(
//   () => import('@/components/onboarding/OnboardingModal.vue')
// )
const TopNavbar = defineAsyncComponent(
  () => import('@/components/TopNavbar.vue')
)
const BottomNavbar = defineAsyncComponent(
  () => import('@/components/BottomNavbar.vue')
)

const { isMobile, isTablet, isDesktop } = useResponsive()
const isCapacitor = computed(() => Capacitor.isNativePlatform())
const isIOS = computed(
  () => isCapacitor.value && Capacitor.getPlatform() === 'ios'
)
const isAndroid = computed(
  () => isCapacitor.value && Capacitor.getPlatform() === 'android'
)
const showOnboardingModal = ref(false)
const authStore = useAuthStore()

// function handleOnboardingError(errorMessage) {
//   console.error('Onboarding error:', errorMessage)
//   notify({
//     title: 'Onboarding Error',
//     text: errorMessage,
//     type: 'error',
//   })
// }

// function closeOnboardingModal() {
//   showOnboardingModal.value = false
// }

onMounted(() => {
  if (authStore.isAuthenticated && authStore.requiresOnboarding) {
    showOnboardingModal.value = true
  }
})
</script>
