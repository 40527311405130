<!-- frontend/src/components/ResponsiveAudioPlayer.vue -->
<template>
  <div :class="audioPlayerClasses">
    <!-- Desktop Layout -->
    <div v-if="isDesktop" class="bg-white border-t border-gray-200 h-14">
      <div class="max-w-screen-xl mx-auto flex items-center h-full px-4">
        <!-- Left Controls: Skip Back, Play/Pause, Skip Forward -->
        <div class="flex items-center space-x-2 mr-4">
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer"
            @click="skipBack"
          >
            <Backward01Icon class="w-5 h-5 fill-current" />
          </button>
          <div
            class="w-9 h-9 bg-white rounded-full flex items-center justify-center cursor-pointer"
            @click="togglePlayPause"
          >
            <component
              :is="isPlaying ? PauseIcon : PlayIcon"
              class="w-6 h-6 text-gray-800 hover:text-primary-hover fill-current"
            />
          </div>
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer"
            @click="skipForward"
          >
            <Forward01Icon class="w-5 h-5 fill-current" />
          </button>
        </div>

        <!-- Middle Controls: Shuffle and Repeat -->
        <div class="flex items-center space-x-4 mr-4">
          <button
            class="text-gray-400 hover:text-gray-600 cursor-pointer"
            @click="toggleShuffle"
          >
            <ShuffleIcon
              class="w-5 h-5 stroke-2"
              :class="{
                'text-secondary hover:text-secondary-hover': isShuffleOn,
              }"
            />
          </button>
          <button
            class="text-gray-400 hover:text-gray-600 cursor-pointer"
            @click="toggleRepeat"
          >
            <RepeatIcon
              class="w-5 h-5 stroke-2"
              :class="{
                'text-secondary hover:text-secondary-hover': isRepeatOn,
              }"
            />
          </button>
        </div>

        <!-- Progress Bar -->
        <div class="flex-1 mx-4">
          <div class="flex items-center">
            <span
              class="text-xs text-gray-500 font-semibold mr-2 w-8 text-right"
            >
              {{ formattedCurrentTime }}
            </span>
            <div
              v-touch:start.passive="startDrag"
              v-touch:move.passive="drag"
              v-touch:end="endDrag"
              class="flex-1 h-1 bg-gray-300 rounded-full relative cursor-pointer"
              @click="handleClick"
              @mousedown="startDrag"
              @mousemove="drag"
              @mouseup="endDrag"
              @mouseleave="endDrag"
            >
              <div
                class="h-full bg-primary"
                :style="{ width: `${progressPercentage}%` }"
              ></div>
              <div
                class="bg-primary rounded-full"
                :style="{ width: progressPercentage + '%' }"
              ></div>
            </div>
            <span class="text-xs text-gray-500 font-semibold ml-2 w-8">
              {{ formattedDuration }}
            </span>
          </div>
        </div>

        <!-- Volume Control -->
        <div class="flex items-center space-x-2 mr-4">
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer"
            @click="toggleMute"
          >
            <component
              :is="isMuted ? VolumeOffIcon : VolumeHighIcon"
              class="w-4 h-4 fill-current"
            />
          </button>
        </div>

        <!-- Right Controls: Cover Art, Title, Favorite, Playlist -->
        <div
          class="flex items-center space-x-3 w-64 cursor-pointer"
          @click="goToStoryDetail"
        >
          <img
            :src="coverArtUrl"
            alt="Current track"
            class="w-8 h-8 rounded flex-shrink-0 cursor-pointer"
          />
          <div class="overflow-hidden flex-grow cursor-pointer">
            <p
              class="text-xs font-medium whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
              @click="goToStoryDetail"
            >
              {{ currentlyPlayingTitle }}
            </p>
          </div>
          <button
            class="text-gray-600 hover:text-primary cursor-pointer"
            @click="toggleFavorite"
          >
            <FavouriteIcon class="w-5 h-5" />
          </button>
          <button
            class="text-gray-600 hover:text-primary cursor-pointer"
            @click="openPlaylist"
          >
            <Playlist01Icon class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile/Tablet Layout -->
    <div v-else class="bg-white flex flex-col items-center">
      <!-- Player Controls -->
      <div
        class="flex items-center justify-between h-12 px-2 sm:px-4 w-full"
        @click="handleMobilePlayerClick"
      >
        <!-- Left: Cover Art and Title -->
        <div class="flex items-center">
          <div class="flex-shrink-0 mr-4">
            <img
              :src="coverArtUrl"
              alt="Current track"
              class="w-10 h-10 rounded cursor-pointer"
            />
          </div>
          <div class="flex flex-col justify-center">
            <p class="text-sm w-64 font-serif truncate cursor-pointer">
              {{ currentlyPlayingTitle }}
            </p>
          </div>
        </div>
        <!-- Right: Controls -->
        <div class="flex items-center space-x-2 flex-shrink-0">
          <!-- Cast Button (for both iOS and Android) -->
          <button
            v-if="isCapacitor"
            class="p-2 rounded-full hover:bg-gray-200 transition"
            @click.stop="showMediacastPicker"
          >
            <SpeakerIcon class="w-6 h-6 text-gray-600" />
          </button>
          <!-- Play/Pause Button -->
          <button
            class="text-gray-600 hover:text-primary-hover cursor-pointer p-2"
            @click.stop="togglePlayPause"
          >
            <component
              :is="isPlaying ? PauseIcon : PlayIcon"
              class="w-6 h-6 fill-current"
            />
          </button>
        </div>
      </div>

      <!-- Thin Progress Bar at the Bottom -->
      <div
        v-touch:start.passive="startDrag"
        v-touch:move.passive="drag"
        v-touch:end="endDrag"
        class="w-full h-0.5 bg-gray-200 relative"
        @click="handleClick"
        @mousedown="startDrag"
        @mousemove="drag"
        @mouseup="endDrag"
        @mouseleave="endDrag"
      >
        <div
          class="h-full bg-primary transition-width duration-300"
          :style="{ width: `${progressPercentage}%` }"
        ></div>
      </div>
    </div>

    <!-- Sliding Window Component -->
    <SlidingWindow
      v-if="shouldShowSlidingWindow"
      :isOpen="isOpen"
      :coverArtUrl="coverArtUrl"
      :title="currentlyPlayingTitle"
      :author="queueStore.currentlyPlaying?.author || ''"
      :bookColors="queueStore.currentlyPlaying?.bookColors"
      :currentTime="currentTime"
      :duration="duration"
      :isPlaying="isPlaying"
      :isRepeatOn="isRepeatOn"
      :isShuffleOn="isShuffleOn"
      :isAndroid="isAndroid"
      @update:isOpen="isOpen = $event"
      @togglePlayPause="togglePlayPause"
      @skipBack="skipBack"
      @skipForward="skipForward"
      @toggleRepeat="toggleRepeat"
      @toggleShuffle="toggleShuffle"
      @seek="seek"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useQueueStore } from '@/stores/queue'
import { useRoute, useRouter } from 'vue-router'
import { useResponsive } from '@/composables/useResponsive'
import {
  Backward01Icon,
  FavouriteIcon,
  Forward01Icon,
  PauseIcon,
  PlayIcon,
  Playlist01Icon,
  RepeatIcon,
  ShuffleIcon,
  VolumeHighIcon,
  VolumeOffIcon,
  SpeakerIcon,
} from '@hugeicons/vue'
import { Capacitor } from '@capacitor/core'
import SlidingWindow from '@/components/SlidingWindow.vue'

// Destructure responsive properties
const { isDesktop, isMobile, isTablet } = useResponsive()

// Determine if running on a native platform
const isCapacitor = computed(() => Capacitor.isNativePlatform())
const isIOS = computed(
  () => isCapacitor.value && Capacitor.getPlatform() === 'ios'
)
const isAndroid = computed(
  () => isCapacitor.value && Capacitor.getPlatform() === 'android'
)

// Vue Router and Vuex Store
const queueStore = useQueueStore()
const route = useRoute()
const router = useRouter()

// State for SlidingWindow
const isOpen = ref(false)

// Audio Player State
const duration = ref(0)
const isPlaying = computed(() => queueStore.isPlaying)
const currentTime = computed(() => queueStore.currentlyPlaying?.progress || 0)
const progressPercentage = computed(() => {
  if (!queueStore.audioPlayer || !queueStore.currentlyPlaying) return 0
  return (currentTime.value / duration.value) * 100 || 0
})

const formattedCurrentTime = computed(() => formatTime(currentTime.value))
const formattedDuration = computed(() => {
  return formatTime(duration.value || queueStore.audioPlayer?.duration || 0)
})

// Utility function to format time in mm:ss
function formatTime(time) {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

// Additional Computed Properties
const isMuted = computed(() => queueStore.isMuted)
const isShuffleOn = computed(() => queueStore.isShuffleOn)
const isRepeatOn = computed(() => queueStore.isRepeatOn)
const currentlyPlayingTitle = computed(
  () => queueStore.currentlyPlaying?.title || 'No Story Playing'
)
const coverArtUrl = computed(
  () => queueStore.currentlyPlaying?.bookCoverUrl || '/placeholder.svg'
)

// Computed Property to Determine When to Show SlidingWindow
const shouldShowSlidingWindow = computed(() => {
  return isCapacitor.value || isMobile.value || isTablet.value
})

// Update Duration when Audio Player Metadata Loads
const updateDuration = () => {
  if (queueStore.audioPlayer) {
    duration.value = queueStore.audioPlayer.duration || 0
  }
}

// Handle Click on Mobile Player to Open SlidingWindow or Navigate
function handleMobilePlayerClick(event) {
  // Prevent default behavior
  event.preventDefault()
  // Open SlidingWindow on both native platforms and small viewports
  if (shouldShowSlidingWindow.value) {
    isOpen.value = true
  } else {
    // Navigate to story detail on larger viewports and non-native platforms
    goToStoryDetail()
  }
}

// Show Mediacast Picker (iOS Only)
const showMediacastPicker = async () => {
  if (isIOS.value) {
    try {
      const { default: Mediacast } = await import('@/plugins/mediacast-plugin')
      await Mediacast.showMediacastPicker()
    } catch (error) {
      console.error('Error showing Mediacast picker:', error)
    }
  }
}

// Play or Pause the Audio
async function togglePlayPause() {
  if (isPlaying.value) {
    await queueStore.pause()
  } else if (queueStore.currentlyPlaying) {
    await queueStore.play()
  } else if (route.name === 'story-detail') {
    const storyId = Number(route.params.id)
    await queueStore.play(storyId)
  }
}

// Skip Backwards by 15 Seconds
function skipBack() {
  if (queueStore.audioPlayer && queueStore.currentlyPlaying) {
    const newTime = Math.max(0, queueStore.audioPlayer.currentTime - 15)
    queueStore.audioPlayer.currentTime = newTime
    queueStore.currentlyPlaying.progress = newTime
  }
}

// Skip Forwards by 15 Seconds
function skipForward() {
  if (queueStore.audioPlayer && queueStore.currentlyPlaying) {
    const newTime = Math.min(
      queueStore.audioPlayer.duration || 0,
      queueStore.audioPlayer.currentTime + 15
    )
    queueStore.audioPlayer.currentTime = newTime
    queueStore.currentlyPlaying.progress = newTime
  }
}

// Seek to a Specific Time in the Audio
function seek(newTime) {
  if (queueStore.audioPlayer && queueStore.currentlyPlaying) {
    queueStore.audioPlayer.currentTime = newTime
    queueStore.currentlyPlaying.progress = newTime
  }
}

// Toggle Shuffle Mode
const toggleShuffle = () => queueStore.toggleShuffle()

// Toggle Repeat Mode
const toggleRepeat = () => queueStore.toggleRepeat()

// Toggle Mute
const toggleMute = () => queueStore.toggleMute()

// Navigate to Story Detail Page
function goToStoryDetail() {
  if (queueStore.currentlyPlaying) {
    router.push({
      name: 'story-detail',
      params: { id: queueStore.currentlyPlaying.id },
    })
  }
}

// Toggle Favorite (Implement Your Logic)
function toggleFavorite() {
  // Implement favorite toggling logic here
  console.log('Toggle favorite')
}

// Open Playlist (Implement Your Logic)
function openPlaylist() {
  // Implement playlist opening logic here
  console.log('Open playlist')
}

// Handle Click on Progress Bar
function handleClick(event) {
  event.preventDefault()
  let clientX
  if (event.type.startsWith('touch')) {
    clientX = event.touches[0].clientX
  } else {
    clientX = event.clientX
  }
  // Calculate the clicked position relative to the progress bar
  const rect = event.target.getBoundingClientRect()
  const x = clientX - rect.left
  const clickedPercentage = x / rect.width
  const newTime = clickedPercentage * duration.value
  seek(newTime)
}

// Drag State
const startX = ref(0)
const currentX = ref(0)
const isDragging = ref(false)

// Start Dragging
function startDrag(event) {
  event.preventDefault?.() // Make preventDefault optional
  isDragging.value = true
  if (event.type === 'touchstart') {
    startX.value = event.touches[0].clientX
  } else {
    startX.value = event.clientX
  }
}

// Handle Dragging
function drag(event) {
  if (!isDragging.value) return
  event.preventDefault?.() // Make preventDefault optional

  if (event.type === 'touchmove') {
    currentX.value = event.touches[0].clientX
  } else {
    currentX.value = event.clientX
  }

  const diff = currentX.value - startX.value
  const rect = event.target.getBoundingClientRect()
  const clickedPercentage = diff / rect.width
  const newTime = clickedPercentage * duration.value + currentTime.value
  seek(newTime)
}

// End Dragging
function endDrag(event) {
  if (!isDragging.value) return
  event.preventDefault?.() // Make preventDefault optional
  isDragging.value = false
}

// Watch for Changes in the Audio Player
watch(
  () => queueStore.audioPlayer,
  newPlayer => {
    if (newPlayer) {
      updateDuration()
      newPlayer.addEventListener('loadedmetadata', updateDuration)
      newPlayer.addEventListener('durationchange', updateDuration)
    }
  },
  { immediate: true }
)

// Lifecycle Hooks
onMounted(() => {
  if (queueStore.audioPlayer) {
    updateDuration()
    queueStore.audioPlayer.addEventListener('loadedmetadata', updateDuration)
    queueStore.audioPlayer.addEventListener('durationchange', updateDuration)
  }
})

onUnmounted(() => {
  if (queueStore.audioPlayer) {
    queueStore.audioPlayer.removeEventListener('loadedmetadata', updateDuration)
    queueStore.audioPlayer.removeEventListener('durationchange', updateDuration)
  }
})

// Computed Classes for the Audio Player Container
const audioPlayerClasses = computed(() => ({
  'bg-white border-t border-gray-200 shadow-lg transition-all duration-300': true,
  'h-12': !isDesktop.value,
  'h-14': isDesktop.value,
}))
</script>

<style scoped>
/* Ensure the audio player is positioned correctly */
.fixed {
  width: 100%;
  /* Additional styling if necessary */
}

/* Optional: Smooth transition when position changes */
div.fixed {
  transition: bottom 0.3s ease-in-out;
}

.touch-none {
  touch-action: none; /* Disables default touch behaviors */
}

/* Optional: Enhance the progress bar appearance */
.progress-container {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #e5e7eb; /* Tailwind gray-200 */
  cursor: pointer;
}

.progress-bar {
  height: 100%;
  background-color: #3b82f6; /* Tailwind primary color */
  border-radius: 9999px;
}

/* Optional: Add a draggable handle */
.progress-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #3b82f6;
  border-radius: 50%;
  cursor: pointer;
}
</style>
