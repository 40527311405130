// frontend/src/main.js
import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Vue3TouchEvents from 'vue3-touch-events'
import { handleError } from './utils/errorHandler'
import { logger } from './utils/logger'
import { useAuthStore } from './stores/auth'
import process from 'process'
import Notifications from '@kyvg/vue3-notification'
import * as Sentry from '@sentry/vue'

const app = createApp(App)
const pinia = createPinia()

if (process.env.NODE_ENV === 'development') {
  window.Vue = app
}

// console.log('VITE_API_BASE_URL:', import.meta.env.VITE_API_BASE_URL)

Sentry.init({
  app,
  dsn: 'https://db933443cf5d233aa4f82ba20056be90@o4508144614703104.ingest.us.sentry.io/4508144679190528',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.talebly\.com\//,
    /^https:\/\/api\.dev\.talebly\.com\//,
    /^https:\/\/tunnel\.dev\.talebly\.com\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  release: SENTRY_RELEASE,
  environment: SENTRY_ENVIRONMENT,
})

app.use(pinia)
app.use(router)
app.use(Notifications)
app.use(Vue3TouchEvents)

// Global error handler
app.config.errorHandler = handleError

// Make logger available globally
app.config.globalProperties.$log = logger

const authStore = useAuthStore()

// Initialize auth without blocking the app mount
authStore.initAuth().catch(error => {
  console.error('Error during auth initialization:', error)
})

// Mount the app immediately
router
  .isReady()
  .then(() => {
    app.mount('#app')
  })
  .catch(error => {
    console.error('Error during router initialization:', error)
  })
