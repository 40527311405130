// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth.js'

// Import layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import NoneLayout from '@/layouts/NoneLayout.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/admin',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/AdminDashboardView.vue'),
    meta: { requiresAuth: true, requiresAdmin: true, layout: DefaultLayout },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/user/Settings.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/views/BrandView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/invite-code',
    name: 'inviteCode',
    component: () => import('@/views/auth/InviteCodeView.vue'),
    meta: { requiresGuest: true, layout: NoneLayout },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/RegisterView.vue'),
    props: route => ({
      inviteCode: route.query.inviteCode,
      error: route.query.error,
    }),
    meta: { requiresGuest: true, layout: NoneLayout },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/LoginView.vue'),
    meta: { requiresGuest: true, layout: NoneLayout },
  },
  {
    path: '/auth/callback',
    name: 'google-auth-callback',
    component: () => import('@/views/auth/GoogleAuthCallback.vue'),
    meta: { requiresGuest: true, layout: NoneLayout },
  },
  {
    path: '/cast',
    name: 'cast',
    component: () => import('@/views/cast/CastView.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/cast/create',
    name: 'cast-member-create',
    component: () => import('@/views/cast/CastMemberCreateView.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/cast/:id',
    name: 'cast-member-detail',
    component: () => import('@/views/cast/CastMemberEditView.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/stories',
    name: 'stories',
    component: () => import('@/views/stories/StoriesView.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/stories/:id',
    name: 'story-detail',
    component: () => import('@/views/stories/StoryDetailView.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/stories/create',
    name: 'story-create',
    component: () => import('@/views/stories/StoryCreateView.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/errors/UnauthorizedView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/views/errors/ForbiddenView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/errors/NotFoundView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/verify-waitlist/:token',
    name: 'verify-waitlist',
    component: () => import('@/views/auth/VerifyWaitlistView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/verify-email/:token',
    name: 'verify-email',
    component: () => import('@/views/auth/VerifyEmailView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/activate-account/:token',
    name: 'activate-account',
    component: () => import('@/views/auth/ActivateAccountView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPasswordView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPasswordView.vue'),
    meta: { layout: NoneLayout },
  },
  {
    path: '/stories-queue',
    name: 'stories-queue',
    component: () => import('@/views/stories/StoriesQueueView.vue'),
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// Remove the loadLayoutMiddleware and its import
// Remove the router.beforeEach that calls loadLayoutMiddleware

// Keep the authentication guard
// Add the navigation guard here
router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore()

  // Wait until the auth state is initialized
  if (auth.isLoading) {
    while (auth.isLoading) {
      await new Promise(resolve => setTimeout(resolve, 50))
    }
  }

  const isAuthenticated = auth.isAuthenticated
  const isAdmin = auth.user?.isAdmin // Assuming you have an isAdmin property on the user object

  // Check if the route requires authentication
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'login' })
  } else if (to.meta.requiresGuest && isAuthenticated) {
    next({ name: 'stories' })
  } else if (to.meta.requiresAdmin && !isAdmin) {
    next({ name: 'forbidden' })
  } else {
    next()
  }
})

export default router
