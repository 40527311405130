<template>
  <div class="app-wrapper h-full">
    <ErrorBoundary>
      <component
        :is="currentLayout"
        v-if="currentLayout"
        :key="currentLayoutKey"
      >
        <RouterView v-slot="{ Component }">
          <Transition name="fade" mode="out-in">
            <component :is="Component" :key="route.fullPath" />
          </Transition>
        </RouterView>
      </component>
    </ErrorBoundary>

    <!-- Global Audio Player -->
    <audio
      v-if="isAuthenticated"
      ref="globalAudioPlayer"
      preload="auto"
    ></audio>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ErrorBoundary from '@/components/ErrorBoundary.vue'
import { useQueueStore } from '@/stores/queue'
import { useAuthStore } from '@/stores/auth'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { Capacitor } from '@capacitor/core'
import { ScreenOrientation } from '@capacitor/screen-orientation'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const queueStore = useQueueStore()
const isAuthenticated = computed(() => authStore.isAuthenticated)
const globalAudioPlayer = ref(null)
const isRouteReady = ref(false)

// Compute the current layout
const currentLayout = computed(() => {
  if (!isRouteReady.value) {
    return null
  }
  if (route.meta && 'layout' in route.meta) {
    return route.meta.layout || DefaultLayout
  }
  return DefaultLayout
})

// Unique key based on layout and route name
const currentLayoutKey = computed(() => {
  return `${currentLayout.value.name}-${route.name}`
})

const isCapacitor = computed(() => Capacitor.isNativePlatform())

onMounted(async () => {
  if (isCapacitor.value) {
    try {
      await ScreenOrientation.lock({ orientation: 'portrait' })
      console.log('Screen orientation locked to portrait')
    } catch (error) {
      console.error('Error locking screen orientation:', error)
    }
  }

  if (isAuthenticated.value && globalAudioPlayer.value) {
    await queueStore.setAudioPlayer(globalAudioPlayer.value)
  }

  router.isReady().then(() => {
    isRouteReady.value = true
  })
})
</script>
