<template>
  <div v-if="error" class="error-boundary pt-safe-top">
    <h2 class="text-secondary">Something went wrong</h2>
    <p>{{ error.message }}</p>
    <button
      class="bg-primary text-primary-foreground px-4 py-2 rounded hover:bg-primary-dark transition-colors duration-200"
      @click="resetError"
    >
      Try again
    </button>
  </div>
  <slot v-else></slot>
</template>

<script setup>
import { ref, onErrorCaptured } from 'vue'

const error = ref(null)

onErrorCaptured(err => {
  error.value = err
  console.log('ErrorBoundary:', error.value)
  return true // Prevent the error from propagating further
})

function resetError() {
  error.value = null
}
</script>
