// src/utils/logger.js

import * as Sentry from '@sentry/vue'

const LOG_LEVELS = {
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 3,
}

// Get log level from environment variable or default to INFO
let currentLogLevel =
  LOG_LEVELS[import.meta.env.VITE_LOG_LEVEL] || LOG_LEVELS.INFO

// Helper function to get console timestamp (HH:mm:ss)
const getConsoleTimestamp = () => {
  const now = new Date()
  return now.toTimeString().split(' ')[0]
}

// Helper function to get full timestamp for Sentry
const getSentryTimestamp = () => {
  const date = new Date()
  return `${date.toISOString().split('.')[0]}Z`
}

// Create a logger factory for stores
export const createStoreLogger = storeName => {
  // Get the calling function name
  const getMethodName = () => {
    try {
      const stack = new Error().stack
      if (!stack) return ''

      // Split the stack trace
      const lines = stack.split('\n')
      if (lines.length < 4) return ''

      // Try different stack trace formats
      const callerLine = lines[3]

      // Format 1: "at functionName (file:line:column)"
      const methodMatch = callerLine.match(/at (?:async )?(\w+)/)
      if (methodMatch) return methodMatch[1]

      // Format 2: "functionName@file:line:column"
      const atMatch = callerLine.match(/(\w+)@/)
      if (atMatch) return atMatch[1]

      return ''
    } catch (error) {
      // If anything fails, return empty string instead of throwing
      console.error('Error getting method name:', error)
      return ''
    }
  }

  return {
    error: (...args) =>
      logger.error({ store: storeName, method: getMethodName() }, ...args),
    warn: (...args) =>
      logger.warn({ store: storeName, method: getMethodName() }, ...args),
    info: (...args) =>
      logger.info({ store: storeName, method: getMethodName() }, ...args),
    debug: (...args) =>
      logger.debug({ store: storeName, method: getMethodName() }, ...args),
  }
}

// Helper function to format context
const formatContext = context => {
  if (!context || (!context.store && !context.method)) return ''
  const store = context.store || ''
  const method = context.method || ''
  if (!store && !method) return ''
  if (!method) return `[${store}]`
  if (!store) return `[${method}]`
  return `[${store}:${method}]`
}

// Helper function to format log messages
const formatLog = (level, context, ...args) => {
  const timestamp = getConsoleTimestamp()
  const contextString = formatContext(context)
  return [
    `[${timestamp}] [${level}]${contextString ? ` ${contextString}` : ''}`,
    ...args,
  ]
}

// Helper function to log to console based on level
const logToConsole = (consoleMethod, level, context, ...args) => {
  // Always log errors in non-production
  if (level === 'ERROR' && import.meta.env.MODE !== 'production') {
    consoleMethod(...formatLog(level, context, ...args))
    return
  }

  // Otherwise respect log level
  if (LOG_LEVELS[level] <= currentLogLevel) {
    consoleMethod(...formatLog(level, context, ...args))
  }
}

// Helper function to log to Sentry based on level
const logToSentry = (level, context, ...args) => {
  // Only log ERROR and WARN to Sentry
  if (level !== 'ERROR' && level !== 'WARN') {
    return
  }

  const message = args
    .map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : String(arg)))
    .join(' ')

  const extra = {
    ...(context?.store && { store: context.store }),
    ...(context?.method && { method: context.method }),
    timestamp: getSentryTimestamp(),
  }

  switch (level) {
    case 'ERROR':
      Sentry.withScope(scope => {
        scope.setExtras(extra)
        Sentry.captureMessage(message, 'error')
      })
      break
    case 'WARN':
      Sentry.withScope(scope => {
        scope.setExtras(extra)
        Sentry.captureMessage(message, 'warning')
      })
      break
  }
}

export const logger = {
  error: (context, ...args) => {
    logToConsole(console.error, 'ERROR', context, ...args)
    logToSentry('ERROR', context, ...args)
  },
  warn: (context, ...args) => {
    logToConsole(console.warn, 'WARN', context, ...args)
    logToSentry('WARN', context, ...args)
  },
  info: (context, ...args) => {
    logToConsole(console.info, 'INFO', context, ...args)
    logToSentry('INFO', context, ...args)
  },
  debug: (context, ...args) => {
    logToConsole(console.debug, 'DEBUG', context, ...args)
    logToSentry('DEBUG', context, ...args)
  },
  setLogLevel: level => {
    if (Object.prototype.hasOwnProperty.call(LOG_LEVELS, level)) {
      currentLogLevel = LOG_LEVELS[level]
      logger.info(
        { store: 'Logger', method: 'setLogLevel' },
        `Log level set to ${level}`
      )
    }
  },
  getLogLevel: () => {
    return Object.keys(LOG_LEVELS).find(
      key => LOG_LEVELS[key] === currentLogLevel
    )
  },
}

export function handleApiError(error, context = {}) {
  // Normalize the error object
  const normalizedError = {
    message: error?.message || 'An unknown error occurred',
    stack: error?.stack || '',
    code: error?.code,
    name: error?.name,
    // Include the raw error for debugging
    originalError: error,
  }

  const errorInfo = {
    message: normalizedError.message,
    location:
      normalizedError.stack?.split('\n')[1]?.trim() || 'unknown location',
    info: {
      action: 'API Error',
      errorName: normalizedError.name,
      errorCode: normalizedError.code,
      ...context,
    },
  }

  // Log to both console and Sentry
  logger.error(
    {
      store: context.store || 'API',
      method: context.method || 'handleApiError',
    },
    errorInfo
  )

  // Re-throw the original error to maintain the stack trace
  throw error
}
