<template>
  <div
    ref="slidingWindowRef"
    v-touch="{
      start: startDrag,
      move: drag,
      end: endDrag,
    }"
    class="fixed inset-0 transform transition-all duration-500 ease-out rounded-xl z-50 pt-safe-top pb-safe-bottom px-3.5 flex flex-col"
    :class="{
      'translate-y-full': !isOpen,
      'translate-y-0': isOpen,
    }"
    :style="currentBackgroundStyle"
  >
    <!-- Handle -->
    <div>
      <div
        class="h-1 w-24 mx-auto mt-1 rounded-full z-50 relative"
        :class="bgClass"
        :style="{ backgroundColor: bookColors?.lightMuted || 'gray' }"
      />
    </div>

    <!-- Header -->
    <div
      class="flex justify-between items-center px-4 pt-2 transition-all duration-300"
    >
      <ArrowDown01Icon class="w-8 h-8 cursor-pointer" @click="closeWindow" />
      <span class="text-md font-semibold">Now Playing</span>
      <MoreHorizontalIcon :size="32" variant="solid" />
    </div>

    <!-- Main Content (non-scrollable) -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Story Cover -->
      <div
        class="px-4 transition-all duration-300 ease-in-out flex justify-center"
        :class="{
          'mt-8': !isQueueOpen,
        }"
      >
        <div
          class="w-full transition-all duration-500 ease-in-out"
          :style="{
            transform: isQueueOpen ? 'scale(0.9)' : 'scale(1)',
          }"
        >
          <img
            :src="coverArtUrl"
            alt="Story Cover"
            class="w-full h-auto object-cover transition-all duration-500 ease-in-out will-change-transform rounded-lg shadow-lg"
          />
        </div>
      </div>

      <!-- Story Info -->
      <div
        class="px-4 text-center transition-all duration-300 ease-in-out"
        :class="{
          'mt-2': isQueueOpen,
          'mt-6': !isQueueOpen,
        }"
      >
        <h2 class="text-md font-serif font-semibold">
          {{ title }}
        </h2>
      </div>

      <!-- Progress Bar -->
      <div
        class="px-4 transition-all duration-300 ease-in-out"
        :class="{
          'mt-2': isQueueOpen,
          'mt-6': !isQueueOpen,
        }"
      >
        <div
          class="bg-gray-300 h-1 rounded-full relative cursor-pointer"
          @click="handleProgressClick"
        >
          <div
            class="bg-gray-600 h-1 rounded-full absolute top-0 left-0"
            :style="{ width: `${progressPercentage}%` }"
          ></div>
        </div>
        <div class="flex justify-between text-xs mt-1">
          <span>{{ formattedCurrentTime }}</span>
          <span>{{ formattedDuration }}</span>
        </div>
      </div>

      <!-- Controls -->
      <div
        class="px-4 transition-all duration-300 ease-in-out"
        :class="{
          'mt-2': isQueueOpen,
          'mt-4': !isQueueOpen,
        }"
      >
        <div class="flex justify-center items-center">
          <div class="flex items-center space-x-4 sm:space-x-8">
            <Backward01Icon
              class="w-8 h-8 sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipBack"
            />
            <GoBackward15SecIcon
              class="w-8 h-8 sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipBack"
            />
            <div
              class="bg-gray-600 bg-opacity-20 rounded-full p-3 cursor-pointer"
              @click="togglePlayPause"
            >
              <component
                :is="isPlaying ? PauseIcon : PlayIcon"
                :class="`w-10 h-10 ${
                  bookColors?.lightMuted
                    ? 'text-[' + bookColors.lightMuted + ']'
                    : 'text-primary-foreground'
                }`"
              />
            </div>
            <GoForward15SecIcon
              class="w-8 h-8 sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipForward"
            />
            <Forward01Icon
              class="w-8 h-8 sm:w-9 sm:h-9 text-gray-600 cursor-pointer"
              @click="skipForward"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Next in Queue Section (scrollable) -->
    <!-- Next in Queue Section (scrollable) -->
    <Transition
      @enter="enterQueue"
      @leave="leaveQueue"
      @after-enter="afterEnterQueue"
      @after-leave="afterLeaveQueue"
    >
      <div
        v-if="isQueueOpen"
        ref="queueRef"
        class="px-4 pb-4 border-t border-gray-300 overflow-hidden flex-shrink-0"
        :style="{ maxHeight: queueMaxHeight + 'px' }"
      >
        <h3 class="text-lg font-semibold mb-2">Next in Queue</h3>
        <!-- Scrollable queue list -->
        <div
          class="overflow-y-auto"
          :style="{ maxHeight: queueMaxHeight - 40 + 'px' }"
        >
          <template v-if="queueStore.queue.length > 0">
            <ul>
              <li
                v-for="(item, index) in queueStore.queue"
                :key="item.id"
                class="text-gray-700 mb-2 flex items-center"
              >
                <img
                  :src="item.bookCoverUrl"
                  :alt="item.title"
                  class="w-10 h-10 object-cover mr-2 rounded"
                />
                <div>
                  <div class="text-xs font-semibold">{{ item.title }}</div>
                </div>
              </li>
            </ul>
          </template>
          <template v-else>
            <div
              class="flex flex-col items-center justify-center py-8 text-gray-500"
            >
              <p class="text-sm">No items in queue</p>
              <p class="text-xs mt-1">
                Add stories to your queue to listen next
              </p>
            </div>
          </template>
        </div>
      </div>
    </Transition>

    <!-- Footer -->
    <div class="mt-auto transition-all duration-300 ease-in-out">
      <div
        class="flex justify-between items-center px-4"
        :class="{
          'mt-1': isQueueOpen,
          'mt-4': !isQueueOpen,
        }"
      >
        <span>
          <button
            class="p-2 rounded-full hover:bg-gray-200 transition"
            @click="showMediacastPicker"
          >
            <SpeakerIcon class="w-6 h-6 text-gray-600" />
          </button>
        </span>
        <LeftToRightListBulletIcon
          class="w-6 h-6 cursor-pointer"
          @click="toggleQueue"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue'
import {
  ArrowDown01Icon,
  MoreHorizontalIcon,
  Backward01Icon,
  GoBackward15SecIcon,
  PlayIcon,
  PauseIcon,
  GoForward15SecIcon,
  Forward01Icon,
  SpeakerIcon,
  LeftToRightListBulletIcon,
} from '@hugeicons/vue'
import { useQueueStore } from '@/stores/queue'
import { Capacitor } from '@capacitor/core'

const queueStore = useQueueStore()

const props = defineProps({
  isOpen: Boolean,
  title: String,
  coverArtUrl: String,
  isPlaying: Boolean,
  currentTime: Number,
  duration: Number,
  bookColors: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits([
  'update:isOpen',
  'togglePlayPause',
  'skipBack',
  'skipForward',
  'seek',
])

const slidingWindowRef = ref(null)
const queueRef = ref(null)
const isQueueOpen = ref(false)
const queueMaxHeight = ref(200)
const isScrollingQueue = ref(false)

const currentBackgroundStyle = computed(() => {
  if (!props.bookColors || Object.keys(props.bookColors).length === 0) {
    console.log('No book colors provided, using default background')
    return getDefaultGradient()
  }

  const { lightVibrant, lightMuted } = props.bookColors
  if (lightVibrant && lightMuted) {
    console.log('Generating gradient with colors:', {
      lightVibrant,
      lightMuted,
    })
    return {
      background: `linear-gradient(to bottom, ${adjustColor(lightMuted, 20)}, ${adjustColor(lightVibrant, 20)}, ${adjustColor(lightVibrant, 20)}, ${adjustColor(lightMuted, 20)})`,
    }
  }

  console.log('Missing some colors, using fallback gradient')
  return getFallbackGradient(lightVibrant || lightMuted)
})

const bgClass = computed(() => {
  return props.bookColors?.lightMuted ? 'bg-opacity-50' : ''
})

const progressPercentage = computed(() => {
  return props.duration > 0 ? (props.currentTime / props.duration) * 100 : 0
})

const formattedCurrentTime = computed(() => {
  return formatTime(props.currentTime)
})

const formattedDuration = computed(() => {
  return formatTime(props.duration)
})

const isCapacitor = computed(() => Capacitor.isNativePlatform())
const isIOS = computed(
  () => isCapacitor.value && Capacitor.getPlatform() === 'ios'
)

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

function closeWindow() {
  emit('update:isOpen', false)
}

function togglePlayPause() {
  emit('togglePlayPause')
}

function skipBack() {
  emit('skipBack')
}

function skipForward() {
  emit('skipForward')
}

function handleProgressClick(event) {
  const rect = event.target.getBoundingClientRect()
  const clickX = event.clientX - rect.left
  const percentage = clickX / rect.width
  emit('seek', percentage * props.duration)
}

async function showMediacastPicker() {
  if (isIOS.value) {
    try {
      const { default: Mediacast } = await import('@/plugins/mediacast-plugin')
      await Mediacast.showMediacastPicker()
    } catch (error) {
      console.error('Error showing Mediacast picker:', error)
    }
  }
}

function toggleQueue() {
  isQueueOpen.value = !isQueueOpen.value
}

// Drag functionality
let startY = 0
let currentY = 0
let lastY = 0
let velocity = 0
let animationFrame = null

function startDrag(event) {
  if (
    isQueueOpen.value &&
    queueRef.value &&
    queueRef.value.contains(event.target)
  ) {
    isScrollingQueue.value = true
    return
  }

  isScrollingQueue.value = false
  startY = event.touches[0].clientY
  currentY = startY
  lastY = startY
  velocity = 0
  if (animationFrame) {
    window.cancelAnimationFrame(animationFrame)
  }
}

function drag(event) {
  if (isScrollingQueue.value) return

  currentY = event.touches[0].clientY
  const deltaY = currentY - startY
  if (deltaY > 0) {
    slidingWindowRef.value.style.transform = `translateY(${deltaY}px)`
  }

  // Calculate velocity
  velocity = currentY - lastY
  lastY = currentY

  if (deltaY > 15) {
    endDrag()
  }
}

function endDrag() {
  if (isScrollingQueue.value) {
    isScrollingQueue.value = false
    return
  }

  const deltaY = currentY - startY

  if (deltaY > 15 || (deltaY > 0 && velocity > 1)) {
    animateClose()
  } else {
    resetPosition()
  }
}

function animateClose() {
  const currentTransform =
    parseFloat(
      slidingWindowRef.value.style.transform
        .replace('translateY(', '')
        .replace('px)', '')
    ) || 0
  const targetTransform = window.innerHeight

  function step() {
    const newTransform = Math.min(currentTransform + velocity, targetTransform)
    slidingWindowRef.value.style.transform = `translateY(${newTransform}px)`

    if (newTransform < targetTransform) {
      velocity *= 1.1 // Increase velocity for acceleration
      animationFrame = window.requestAnimationFrame(step)
    } else {
      closeWindow()
      resetPosition()
    }
  }

  animationFrame = window.requestAnimationFrame(step)
}

function resetPosition() {
  slidingWindowRef.value.style.transform = ''
  if (animationFrame) {
    window.cancelAnimationFrame(animationFrame)
  }
}

// Queue transition methods
function enterQueue(el, done) {
  // Implement enter animation
  done()
}

function leaveQueue(el, done) {
  // Implement leave animation
  done()
}

function afterEnterQueue() {
  // Actions after enter animation
}

function afterLeaveQueue() {
  // Actions after leave animation
}

// Calculate available height for the queue
const calculateQueueHeight = () => {
  if (!slidingWindowRef.value || !queueRef.value) return

  const windowHeight = window.innerHeight
  const mainContentHeight =
    slidingWindowRef.value.offsetHeight - (queueRef.value?.offsetHeight || 0)
  queueMaxHeight.value = Math.max(100, windowHeight - mainContentHeight - 100) // Ensure a minimum height and subtract some padding
}

// Call this function when the component mounts and when the window resizes
onMounted(() => {
  nextTick(() => {
    calculateQueueHeight()
    window.addEventListener('resize', calculateQueueHeight)
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', calculateQueueHeight)
  if (animationFrame) {
    window.cancelAnimationFrame(animationFrame)
  }
})

// Watch for changes in isOpen prop
watch(
  () => props.isOpen,
  newIsOpen => {
    if (newIsOpen) {
      resetPosition()
    }
  }
)

// Function to get default gradient based on color mode
function getDefaultGradient() {
  return {
    background: 'linear-gradient(to bottom, #f0f0f0, #e0e0e0)',
  }
}

// Function to get a fallback gradient when some colors are missing
function getFallbackGradient(color) {
  if (!color) return getDefaultGradient()
  const lighterColor = adjustColor(color, 20)
  const darkerColor = adjustColor(color, -20)
  return {
    background: `linear-gradient(to bottom, ${lighterColor}, ${darkerColor})`,
  }
}

// Function to adjust color lightness
function adjustColor(color, amount) {
  const rgb = hexToRgb(color)
  const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b)
  hsl[2] = Math.min(100, hsl[2] + amount) // Increase lightness, max 100
  const adjustedRgb = hslToRgb(hsl[0], hsl[1], hsl[2])
  return rgbToHex(adjustedRgb[0], adjustedRgb[1], adjustedRgb[2])
}

// Helper functions for color conversions
function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

function rgbToHsl(r, g, b) {
  ;(r /= 255), (g /= 255), (b /= 255)
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h, s
  const l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h * 360, s * 100, l * 100]
}

function hslToRgb(h, s, l) {
  ;(h /= 360), (s /= 100), (l /= 100)
  let r, g, b

  if (s === 0) {
    r = g = b = l
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

function rgbToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}
</script>
